exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-releases-mac-js": () => import("./../../../src/pages/blog/releases/mac.js" /* webpackChunkName: "component---src-pages-blog-releases-mac-js" */),
  "component---src-pages-blog-releases-mac-web-js": () => import("./../../../src/pages/blog/releases/mac-web.js" /* webpackChunkName: "component---src-pages-blog-releases-mac-web-js" */),
  "component---src-pages-blog-releases-windows-js": () => import("./../../../src/pages/blog/releases/windows.js" /* webpackChunkName: "component---src-pages-blog-releases-windows-js" */),
  "component---src-pages-blog-releases-windows-web-js": () => import("./../../../src/pages/blog/releases/windows-web.js" /* webpackChunkName: "component---src-pages-blog-releases-windows-web-js" */),
  "component---src-pages-company-about-us-js": () => import("./../../../src/pages/company/about-us.js" /* webpackChunkName: "component---src-pages-company-about-us-js" */),
  "component---src-pages-company-contact-us-js": () => import("./../../../src/pages/company/contact-us.js" /* webpackChunkName: "component---src-pages-company-contact-us-js" */),
  "component---src-pages-company-jobs-js": () => import("./../../../src/pages/company/jobs.js" /* webpackChunkName: "component---src-pages-company-jobs-js" */),
  "component---src-pages-downloads-android-js": () => import("./../../../src/pages/downloads/android.js" /* webpackChunkName: "component---src-pages-downloads-android-js" */),
  "component---src-pages-downloads-complete-classic-js": () => import("./../../../src/pages/downloads/complete/classic.js" /* webpackChunkName: "component---src-pages-downloads-complete-classic-js" */),
  "component---src-pages-downloads-complete-index-js": () => import("./../../../src/pages/downloads/complete/index.js" /* webpackChunkName: "component---src-pages-downloads-complete-index-js" */),
  "component---src-pages-downloads-mac-js": () => import("./../../../src/pages/downloads/mac.js" /* webpackChunkName: "component---src-pages-downloads-mac-js" */),
  "component---src-pages-downloads-windows-js": () => import("./../../../src/pages/downloads/windows.js" /* webpackChunkName: "component---src-pages-downloads-windows-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-copyright-js": () => import("./../../../src/pages/legal/copyright.js" /* webpackChunkName: "component---src-pages-legal-copyright-js" */),
  "component---src-pages-legal-eula-bittorrent-classic-pro-js": () => import("./../../../src/pages/legal/eula/bittorrent-classic-pro.js" /* webpackChunkName: "component---src-pages-legal-eula-bittorrent-classic-pro-js" */),
  "component---src-pages-legal-eula-js": () => import("./../../../src/pages/legal/eula.js" /* webpackChunkName: "component---src-pages-legal-eula-js" */),
  "component---src-pages-legal-merchant-directory-js": () => import("./../../../src/pages/legal/merchant-directory.js" /* webpackChunkName: "component---src-pages-legal-merchant-directory-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-use-js": () => import("./../../../src/pages/legal/terms-of-use.js" /* webpackChunkName: "component---src-pages-legal-terms-of-use-js" */),
  "component---src-pages-platforms-android-js": () => import("./../../../src/pages/platforms/android.js" /* webpackChunkName: "component---src-pages-platforms-android-js" */),
  "component---src-pages-platforms-mac-js": () => import("./../../../src/pages/platforms/mac.js" /* webpackChunkName: "component---src-pages-platforms-mac-js" */),
  "component---src-pages-platforms-windows-js": () => import("./../../../src/pages/platforms/windows.js" /* webpackChunkName: "component---src-pages-platforms-windows-js" */),
  "component---src-pages-pressroom-index-js": () => import("./../../../src/pages/pressroom/index.js" /* webpackChunkName: "component---src-pages-pressroom-index-js" */),
  "component---src-pages-prodnews-index-js": () => import("./../../../src/pages/prodnews/index.js" /* webpackChunkName: "component---src-pages-prodnews-index-js" */),
  "component---src-pages-products-android-bittorrent-android-free-js": () => import("./../../../src/pages/products/android/bittorrent-android-free.js" /* webpackChunkName: "component---src-pages-products-android-bittorrent-android-free-js" */),
  "component---src-pages-products-android-bittorrent-android-pro-js": () => import("./../../../src/pages/products/android/bittorrent-android-pro.js" /* webpackChunkName: "component---src-pages-products-android-bittorrent-android-pro-js" */),
  "component---src-pages-products-content-utilities-bittorrent-remote-js": () => import("./../../../src/pages/products/content-utilities/bittorrent-remote.js" /* webpackChunkName: "component---src-pages-products-content-utilities-bittorrent-remote-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-mac-bittorrent-web-compare-js": () => import("./../../../src/pages/products/mac/bittorrent-web-compare.js" /* webpackChunkName: "component---src-pages-products-mac-bittorrent-web-compare-js" */),
  "component---src-pages-products-mac-bittorrent-web-free-js": () => import("./../../../src/pages/products/mac/bittorrent-web-free.js" /* webpackChunkName: "component---src-pages-products-mac-bittorrent-web-free-js" */),
  "component---src-pages-products-win-bittorrent-classic-compare-js": () => import("./../../../src/pages/products/win/bittorrent-classic-compare.js" /* webpackChunkName: "component---src-pages-products-win-bittorrent-classic-compare-js" */),
  "component---src-pages-products-win-bittorrent-classic-free-js": () => import("./../../../src/pages/products/win/bittorrent-classic-free.js" /* webpackChunkName: "component---src-pages-products-win-bittorrent-classic-free-js" */),
  "component---src-pages-products-win-bittorrent-classic-premium-js": () => import("./../../../src/pages/products/win/bittorrent-classic-premium.js" /* webpackChunkName: "component---src-pages-products-win-bittorrent-classic-premium-js" */),
  "component---src-pages-products-win-bittorrent-web-compare-js": () => import("./../../../src/pages/products/win/bittorrent-web-compare.js" /* webpackChunkName: "component---src-pages-products-win-bittorrent-web-compare-js" */),
  "component---src-pages-products-win-bittorrent-web-free-js": () => import("./../../../src/pages/products/win/bittorrent-web-free.js" /* webpackChunkName: "component---src-pages-products-win-bittorrent-web-free-js" */),
  "component---src-pages-products-win-bittorrent-web-premium-js": () => import("./../../../src/pages/products/win/bittorrent-web-premium.js" /* webpackChunkName: "component---src-pages-products-win-bittorrent-web-premium-js" */),
  "component---src-pages-sr-js": () => import("./../../../src/pages/sr.js" /* webpackChunkName: "component---src-pages-sr-js" */),
  "component---src-pages-tip-of-the-day-js": () => import("./../../../src/pages/tip-of-the-day.js" /* webpackChunkName: "component---src-pages-tip-of-the-day-js" */),
  "component---src-pages-token-bittorrent-file-system-faq-js": () => import("./../../../src/pages/token/bittorrent-file-system/faq.js" /* webpackChunkName: "component---src-pages-token-bittorrent-file-system-faq-js" */),
  "component---src-pages-token-bittorrent-file-system-js": () => import("./../../../src/pages/token/bittorrent-file-system.js" /* webpackChunkName: "component---src-pages-token-bittorrent-file-system-js" */),
  "component---src-pages-token-bittorrent-speed-faq-js": () => import("./../../../src/pages/token/bittorrent-speed/faq.js" /* webpackChunkName: "component---src-pages-token-bittorrent-speed-faq-js" */),
  "component---src-pages-token-bittorrent-speed-js": () => import("./../../../src/pages/token/bittorrent-speed.js" /* webpackChunkName: "component---src-pages-token-bittorrent-speed-js" */),
  "component---src-pages-token-btt-airdrop-js": () => import("./../../../src/pages/token/btt/airdrop.js" /* webpackChunkName: "component---src-pages-token-btt-airdrop-js" */),
  "component---src-pages-token-btt-js": () => import("./../../../src/pages/token/btt.js" /* webpackChunkName: "component---src-pages-token-btt-js" */),
  "component---src-templates-blog-blog-author-js": () => import("./../../../src/templates/blog/blog-author.js" /* webpackChunkName: "component---src-templates-blog-blog-author-js" */),
  "component---src-templates-blog-blog-category-js": () => import("./../../../src/templates/blog/blog-category.js" /* webpackChunkName: "component---src-templates-blog-blog-category-js" */),
  "component---src-templates-blog-blog-index-js": () => import("./../../../src/templates/blog/blog-index.js" /* webpackChunkName: "component---src-templates-blog-blog-index-js" */),
  "component---src-templates-blog-blog-list-js": () => import("./../../../src/templates/blog/blog-list.js" /* webpackChunkName: "component---src-templates-blog-blog-list-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-help-center-help-center-article-js": () => import("./../../../src/templates/help-center/helpCenterArticle.js" /* webpackChunkName: "component---src-templates-help-center-help-center-article-js" */),
  "component---src-templates-help-center-help-center-home-js": () => import("./../../../src/templates/help-center/helpCenterHome.js" /* webpackChunkName: "component---src-templates-help-center-help-center-home-js" */),
  "component---src-templates-help-center-help-center-search-page-js": () => import("./../../../src/templates/help-center/helpCenterSearchPage.js" /* webpackChunkName: "component---src-templates-help-center-help-center-search-page-js" */),
  "component---src-templates-help-center-help-center-single-article-js": () => import("./../../../src/templates/help-center/helpCenterSingleArticle.js" /* webpackChunkName: "component---src-templates-help-center-help-center-single-article-js" */),
  "component---src-templates-help-center-help-center-single-solution-js": () => import("./../../../src/templates/help-center/helpCenterSingleSolution.js" /* webpackChunkName: "component---src-templates-help-center-help-center-single-solution-js" */),
  "component---src-templates-help-center-help-center-solution-js": () => import("./../../../src/templates/help-center/helpCenterSolution.js" /* webpackChunkName: "component---src-templates-help-center-help-center-solution-js" */),
  "component---src-templates-help-center-help-center-submit-ticket-js": () => import("./../../../src/templates/help-center/helpCenterSubmitTicket.js" /* webpackChunkName: "component---src-templates-help-center-help-center-submit-ticket-js" */),
  "component---src-templates-tip-post-js": () => import("./../../../src/templates/tip-post.js" /* webpackChunkName: "component---src-templates-tip-post-js" */)
}

