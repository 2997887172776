import React, { useState, useContext } from "react"
import LocalizedLink from "./localizedLink"
import { Link } from "gatsby"
import * as styles from "./navPopup.module.css"
import { NavContext } from "./NavContext"
import { CSSTransition } from 'react-transition-group';
import { FaWindows, FaApple } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import useTranslations from "../utils/useTranslations"
import { unlocalizedPageCheck } from "../utils/unlocalizedPageCheck"
import bttLogo from "../images/components/navpopup/btt-token-logomark.svg"
import Binance from "../images/token/btt/exchange-logos/Binance.png"
import Bittrex from "../images/token/btt/exchange-logos/Bittrex.png"
import Huobi from "../images/token/btt/exchange-logos/Huobi.png"
import Upbit from "../images/token/btt/exchange-logos/Upbit.png"
import Poloniex from "../images/components/navpopup/poloniex.png"
import krakenLogo from "../images/components/navpopup/kraken-logo.svg"
import utLogo from "../images/components/navpopup/utorrent-logo-black.svg"
import SimplexExample from "../images/simplex/simplex-example.png"
// import SimplexIframeWidget from "./simplexIframeWidget"

const NavPopup = (props) => {
	const t = useTranslations(props.navText)
	const navContext = useContext(NavContext);

	const [ acceptedTerms, setAcceptedTerms ] = useState(false);
	const [ isModalOpen, setIsModalOpen ] = useState(false);

	const handleLanguageClick = (e, value) => {
		props.togglePopup(null)
		let isUnlocalizedPage = unlocalizedPageCheck(props.unlocalizedPath)
		if(isUnlocalizedPage){
			e.preventDefault()
			sessionStorage.setItem('locale', value)
			props.compatibilityContext(e, 'blockedLanguage', '', [])

		}
	}

	const checkboxHandler = () => {
		setAcceptedTerms(accepted => !accepted);
		setIsModalOpen(true);
	}

	const closeModal = (e) =>{
		e.preventDefault();
		setIsModalOpen(false);
	}

	const q = {
		Products:
			<>
				<div className="col-4 d-inline-block align-top ml-4 ml-md-0" >
					<p className="mb-3 text-uppercase nav-popup-section-header">{t("Torrent Downloaders")}</p>
					<div className={`mt-2 mb-2 ${styles.selection}`}>
			  			<p className={`mb-0`}>
			  				<span className="font-weight-bold bt-font">BitTorrent </span> 
			  				{t("Web")}
			  			</p>
			  			<div  className={`d-block ${styles.selectionSystems}`} >
							<LocalizedLink id="mainnav-products-btweb-windows" to="/products/win/bittorrent-web-free/" onClick={() => {props.togglePopup(null)}}>
								<div className={`d-inline-block mt-1 ${styles.img}`} >
									<FaWindows />
									Windows
								</div>
							</LocalizedLink>
							<span className={styles.verticalLine}> | </span>
							<LocalizedLink id="mainnav-products-btweb-mac" to="/products/mac/bittorrent-web-free/" onClick={() => {props.togglePopup(null)}}>
								<div className={`d-inline-block mt-1 ${styles.img}`}>
									<FaApple />
									Mac
								</div>
							</LocalizedLink>
						</div>
					</div>
					<div className={`mt-2 mb-2 ${styles.selection}`}>
			  			<p className="mb-0">
			  				<span className="font-weight-bold bt-font">BitTorrent </span> 
			  				{t("Classic")}
			  			</p>
			  			<div  className={`d-block ${styles.selectionSystems}`} >
							<LocalizedLink id="mainnav-products-btclassic-windows" to="/products/win/bittorrent-classic-free/" onClick={() => {props.togglePopup(null)}}>
								<div className={`d-inline-block mt-1 ${styles.img}`} >
									<FaWindows />
									Windows
								</div>
							</LocalizedLink>
						</div>

					</div>
					<div className="mt-2 mb-4">
						<LocalizedLink  className={styles.selection} id="mainnav-products-android" to="/products/android/bittorrent-android-free/" onClick={() => {props.togglePopup(null)}}>
				  			<p className="mb-1">
				  				<span className="font-weight-bold bt-font">BitTorrent </span> 
				  				{t("Android")}
				  			</p>
						</LocalizedLink>
					</div>
					<div className="mt-2 mb-4">
						<div className={`${styles.selection} ${styles.selectionDisabled}`} id="mainnav-products-btspeed">
							<p className="mb-1">
								<span className="font-weight-bold bt-font">BitTorrent </span>
								{t("Speed")}
							</p>
						</div>
					</div>
				</div>
				<div className="col-4 d-inline-block align-top ml-4 ml-md-0" >
					<p className="mb-3 text-uppercase nav-popup-section-header">{t("Decentralized Storage")}</p>
					<div className="mt-4 mb-4">
						<LocalizedLink  className={styles.selection} id="mainnav-products-btfs" to="/token/bittorrent-file-system/" onClick={() => {props.togglePopup(null)}}>
				  			<p className="mb-1 text-nowrap">
				  				<span className="font-weight-bold bt-font">BitTorrent </span> 
				  				{t("File System")}
				  			</p>
						</LocalizedLink>
					</div>
				</div>

				<div className="col-12 d-block align-top p-0" >
					<div className="col-4 d-inline-block align-top" >
						<p className="mb-3 text-uppercase nav-popup-section-header">{t("Content Utility")}</p>
						<div className="mt-2 mb-4">
				  			<a  className={styles.selection} id="mainnav-products-btremote" target="_blank" rel="noopener noreferrer" href="https://remote.bittorrent.com" onClick={() => {props.togglePopup(null)}}>
				  				<span className="font-weight-bold bt-font">BitTorrent </span>
				  				{t("Remote")}
				  			</a>
						</div>
					</div>
					<div className="col-8 d-inline-block align-top" >
						<p className="mb-3 text-uppercase nav-popup-section-header">{t("More Products")}</p>
						<div className="mt-2 pt-3">
				  			<p className="m-0">Did you know <img src={utLogo} className="nav-ut-logo" alt="µTorrent"/> is part of the BitTorrent family of products? <a href="https://www.utorrent.com" target="_blank" rel="noreferrer noopener" id="mainnav-products-ut-learnmore" className="font-weight-bold text-dark d-inline-block">{t(`Learn more`)} ></a> </p>
						</div>
					</div>
				</div>

			</>,
			
		BTT:
			<>
				<div className="position-relative row">
				{!props.isMobile && !props.isSimplexSupported ? (
					<>
						<div className="col-lg-7 d-inline-block align-top ml-0 mb-5 p-4 pr-lg-0">
							<div className="row mx-0">
								<div className="col-12 py-0 px-0">
									<p className="mb-2 text-size-20">{t(`BTT is now available on`)}</p>
									<a className="pointer" id="mainnav-btt-kraken-logo" href="https://www.kraken.com/" target="_blank" rel="noreferrer noopener">
										<img src={krakenLogo} className="mt-0 mb-4" alt="Kraken"/>
									</a>
								</div>
								<div className="col-12 px-0 my-2">
									<ul className={styles.krakenList}>
										<li>{t(`Available to U.S users, you can purchase BTT in USD or EURO`)}</li>
										<li>{t(`Increase your BTT balance, leverage it within the BitTorrent Speed App`)}</li>
										<li>{t(`Drive faster download speeds`)}</li>
									</ul>
								</div>
								<div className="col-12 px-0">
									<Link className="text-dark text-decoration-none feature-list-learn-more font-weight-bold" id="mainnav-btt-kraken-learnmore" to="/blog/2022/09/08/buy-bittorrent-token-btt-on-krakens-leading-digital-exchange/" onClick={() => {props.togglePopup(null)}}>
										{t(`Learn more`)}
									</Link>
									<div className="ml-1 feature-list-arrow-black d-inline-block font-weight-bold align-middle" alt="learn more">{`>`}</div>
								</div>
							</div>
							<div className="row mt-5 mx-0">
								<div className={`col-4 pl-0 pr-md-2 pl-md-0 pt-0 pt-lg-0 ${styles.exchangesListing}`}><a id="mainnav-btt-exchanges-logo-Poloniex" href="https://www.poloniex.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Poloniex} alt="Poloniex"/></a></div>
								<div className={`col-4 px-md-2 pt-0 pt-lg-0 ${styles.exchangesListing}`}><a id="mainnav-btt-exchanges-logo-Bittrex" href="https://bittrex.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bittrex} alt="Bittrex"/></a></div>
								<div className={`col-4 px-md-2 pt-0 pt-lg-0 ${styles.exchangesListing}`}><a id="mainnav-btt-exchanges-logo-Huobi" href="https://www.huobiwallet.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Huobi} alt="Huobi"/></a></div>
								<div className={`col-4 pl-0 px-md-2 pt-0 pt-lg-0 ${styles.exchangesListing}`}><a id="mainnav-btt-exchanges-logo-Upbit" href="https://upbit.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Upbit} alt="Upbit"/></a></div>
								<div className={`col-4 px-md-2 pt-0 pt-lg-0 ${styles.exchangesListing}`}><a id="mainnav-btt-exchanges-logo-Binance" href="https://www.binance.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Binance} alt="Binance"/></a></div>
								<div className="col-12 pt-3 text-left px-0">
									<LocalizedLink  className="text-dark text-decoration-none feature-list-learn-more font-weight-bold" id="mainnav-btt-viewall-xchanges" to="/token/btt/#exchanges" onClick={() => {props.togglePopup(null)}}>
										{t(`View All Exchanges`)}
									</LocalizedLink>
									<div className="ml-1 feature-list-arrow-black d-inline-block font-weight-bold align-middle" alt="learn more">{`>`}</div>
								</div>
							</div>
						</div>
						</>		
						) : (	
						<>
							<div className="col-lg-6 d-inline-block align-top ml-4 ml-md-0 mb-5 px-4">
								<p>  
									{t("Your purchase of digital coins via credit card is facilitated by a third party service provider, Simplex Payment Services UAB.")}
								</p>
								<p className="font-weight-bold">
									{t("BitTorrent does not facilitate this transaction and expressly disclaims all warranties related to this third party service.")}
								</p>
								<p>
									{t("The crypto amount quoted is based on the current rate and is not final.")}
								</p>
								<p>
									{t("If the rate changes by more than +/-2.5% you will be asked to reconfirm the transaction via email from Simplex.")}
								</p>
								<p>
									{t("BTT is not available for direct purchase from all geographic locations.")}
								</p>
								<p>
									{t(`If you have any questions, please contact Simplex customer support at support@simplex.com.`, {
										"support@simplex.com": <a href="https://support.simplex.com/" target="_blank" rel="noopener noreferrer" className="ml-1 simplex-support-link">support@simplex.com</a>
									})}
								</p>
								<div className="simplex-accept-terms-container p-3">
									<p className="font-weight-bold">{t("Please read the above and check the box to continue")}</p>
									<p className="mb-0 d-flex align-middle">{t("I have read and accept the terms of this purchase.")}
										<input id="mainnav-simplex-checkbox" type="checkbox" className="ml-2 terms-checkbox" onChange={checkboxHandler} />
									</p>
								</div>
							</div>
							<div className="col-lg-6 d-flex align-top justify-content-center">
								<img src={SimplexExample} alt="Simplex Example" className="simplex-example-image"/>
							</div>
							{/* <SimplexIframeWidget acceptedTerms={acceptedTerms} isModalOpen={isModalOpen} close={closeModal}/>  */}
						</>
					)}	
				</div>
			</>,

		Language:
			<>
				<div className="row">
					<div className="col-4">
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "en")}} to={ props.unlocalizedPath } hrefLang="en"  id="mainnav-lang-en" className="dropdown-item">
					          English
					        </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "zh-cn")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/zh-cn'+ props.unlocalizedPath } hrefLang="zh_CN" id="mainnav-lang-zh-cn" className="dropdown-item">
			                  中文（简体）
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "zh-tw")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/zh-tw'+ props.unlocalizedPath } hrefLang="zh_TW" id="mainnav-lang-zh-tw" className="dropdown-item">
			                  中文（繁體）
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "de")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/de'+ props.unlocalizedPath } hrefLang="de" id="mainnav-lang-de" className="dropdown-item">
			                  Deutsch
			                </Link>
			  			</div>
			  			<div >
					  		<Link onClick={(e) => {handleLanguageClick(e, "es")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/es'+ props.unlocalizedPath } hrefLang="es" id="mainnav-lang-es" className="dropdown-item">
			                  Español
			                </Link>
			  			</div>
			  			<div >
					  		<Link onClick={(e) => {handleLanguageClick(e, "fr")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/fr'+ props.unlocalizedPath } hrefLang="fr" id="mainnav-lang-fr" className="dropdown-item">
			                  Français
			                </Link>
			  			</div>
					</div>
					<div className="col-4">
						<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "hi")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/hi'+ props.unlocalizedPath } hrefLang="hi" id="mainnav-lang-hi" className="dropdown-item">
			                  हिन्दी
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "it")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/it'+ props.unlocalizedPath } hrefLang="it" id="mainnav-lang-it" className="dropdown-item">
			                  Italiano
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "ja")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/ja'+ props.unlocalizedPath } hrefLang="ja" id="mainnav-lang-ja" className="dropdown-item">
			                  日本語
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "ko")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/ko'+ props.unlocalizedPath } hrefLang="ko" id="mainnav-lang-ko" className="dropdown-item">
			                  한국어
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "nl")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/nl'+ props.unlocalizedPath } hrefLang="nl" id="mainnav-lang-nl" className="dropdown-item">
			                  Nederlands
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "pl")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/pl'+ props.unlocalizedPath } hrefLang="pl" id="mainnav-lang-pl" className="dropdown-item">
			                  Polski
			                </Link>
			  			</div>
					</div>
					<div className="col-4">
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "pt-br")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/pt-br'+ props.unlocalizedPath } hrefLang="pt_BR" id="mainnav-lang-pt-br" className="dropdown-item">
			                  Português (Brasil)
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "pt")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/pt'+ props.unlocalizedPath } hrefLang="pt" id="mainnav-lang-pt" className="dropdown-item">
			                  Português
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "ru")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/ru'+ props.unlocalizedPath } hrefLang="ru" id="mainnav-lang-ru" className="dropdown-item">
			                  Русский
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "tl")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/tl'+ props.unlocalizedPath } hrefLang="tl" id="mainnav-lang-tl" className="dropdown-item">
			                  Tagalog
			                </Link>
			  			</div>
			  			<div>
					  		<Link onClick={(e) => {handleLanguageClick(e, "tr")}} to={ unlocalizedPageCheck(props.unlocalizedPath) ? props.unlocalizedPath : '/tr'+ props.unlocalizedPath } hrefLang="tr" id="mainnav-lang-tr" className="dropdown-item">
			                  Türkçe
			                </Link>
			  			</div>
					</div>
		  			
		        </div>
			</>

	}

	var test = (navContext.selected === null) ? navContext.selected : t(navContext.selected)

	return (
		<CSSTransition in={navContext.isPopupOpen} timeout={400} classNames="slide">
			<div className={styles.popup}>
				<div className={styles.popupInner}>
					<div className="container h-100 position-relative p-0 mb-5 pb-5 pt-4 pt-lg-0">
						<h2 className={`col-12 d-none d-lg-inline-block mt-4 mt-xl-5 mb-3 ${navContext.selected === 'BTT' ? `mb-xl-3 pb-3 px-0 ${styles.bttHeader}` : 'mb-xl-5'} nav-popup-header`}>
							{
								navContext.selected === "BTT" &&
								<span className="mr-3">
									Exchanges listing 
								</span>
							}
							<img src={bttLogo} className={`mr-4  ${navContext.selected !== 'BTT' ? 'd-none' : ''}`} alt="BTT" />
							{test}
							<LocalizedLink className={`${styles.selection} align-top ml-3`} id="mainnav-products-viewall" to="/products/" onClick={() => {props.togglePopup(null)}}>
								<span className={`text-uppercase small border border-dark py-1 px-2  ${navContext.selected !== 'Products' ? 'd-none' : ''}`}  style={{ fontSize: `12px` }}>{t(`View All`)}</span>
							</LocalizedLink>
						</h2>
						{navContext.selected !== "BTT" && q[`${navContext.selected}`]}
						<div style={{display: navContext.selected==="BTT" ? "block" : "none"}}>{q["BTT"]}</div>
						<button className={`${styles.close} btn-no-styling`} onClick={()=>{props.togglePopup(null)}}>
							<IoMdClose/>
						</button>
					</div>
				</div>
			</div>
		</CSSTransition>
	 )
}

export default NavPopup