import React from "react";
import useTranslations from '../utils/useTranslations'
import { getAndroidLink } from "../utils/getAndroidLink";

const AndroidBanner = (props) => {
	const {text} = props;
	const t = useTranslations(text)

	const locale = props.locale
	const badgeLink = `/images/google-play-badges/google-play-badge-${locale}.png`

	return(
		<div className="android-banner-container">
			<div className="android-banner-small">
				<div className="row mx-0 h-100 justify-content-center flex-nowrap" style={{columnGap: '10px'}}>
					<div className="d-flex align-items-center">
						{t(`Get the BitTorrent App`)}
					</div>
					<div className="h-100 d-flex align-items-center">
						<a className="pointer" id="android-banner-google-play-store-btn" href={getAndroidLink('free')} target="_blank" rel="noopener noreferrer">
							<img className="d-block img-fluid" src={badgeLink} alt="BitTorrent Android Google Play" style={{width: '7.65em'}}/>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AndroidBanner;
