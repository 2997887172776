import React, {useEffect, useState} from 'react';
import Navigation from "./navigation"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import AndroidBanner from "./androidBanner"
import CompatibilityPopup from "../components/compatibilityPopup"
import { unlocalizedPageCheck } from "../utils/unlocalizedPageCheck"
import faviconNew from "../images/faviconNew.ico"
import { NavToggleContext } from "../components/navToggleContext"
import { getShowPromoBanner, PromoBanner } from "../components/promo"
import LiteBanner from "../components/LiteBanner"
import { getCountry } from '../utils';

const LocaleContext = React.createContext()

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale, unlocalizedPath, pageName, navText, meta, footerText, compatibilityText, androidBannerText} }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
      topOnEmptyHash:false,
      header:'.navbar'
    })
  }

  const { data } = useStaticQuery(query)
  const siteUrl = data.siteMetadata.siteUrl
  let localeStr = locale.replace('_','-')
  const ogUrl = (locale === 'en') ? siteUrl+unlocalizedPath : `${siteUrl}/${localeStr}${unlocalizedPath}`
  const description = stripHTML(meta.description)
  const socialImg = (meta.socialImgUrl) ? 'https://www.bittorrent.com/social/'+ meta.socialImgUrl : 'https://www.bittorrent.com/social/bittorrent.png'

  const isHomepage = pageName === "index"
  const isBTWebFree = pageName === "products_win_bittorrent-web-free"
  const isBTWebFreeMac = pageName === "products_mac_bittorrent-web-free"
  const isBTWebPrem = pageName === "products_win_bittorrent-web-premium"
  const isClassFree = pageName === "products_win_bittorrent-classic-free"
  const isClassPrem = pageName === "products_win_bittorrent-classic-premium"
  const isAndroFree = pageName === "products_android_bittorrent-android-free"
  const isAndroPro  = pageName === "products_android_bittorrent-android-pro"

  const homepageStructuredData = <>
    <script type='application/ld+json'>
      {`{
        "@context": "https://schema.org",
        "@type": "Corporation",
        "name": "BitTorrent, Ltd.",
        "url": "https://www.bittorrent.com",
        "logo": "https://cdn-assets.bittorrent.com/knowledge-panel/BitTorrent_Logo_Black_Block.png",
        "sameAs": [
          "https://www.facebook.com/bittorrent",
          "https://twitter.com/bittorrent",
          "https://www.instagram.com/bittorrent/",
          "https://www.youtube.com/channel/UCzYgPFi_9RqlZI6I7YQDh_Q"
        ]
      }`}
    </script>
  </>

  const btwebfreeStructuredData = <>
    <script type='application/ld+json'>
      {`{  
        "@context" : "http://schema.org",  
        "@type" : "SoftwareApplication",  
        "name" : "BitTorrent Web",  
        "url" : "https://www.bittorrent.com/products/win/bittorrent-web-free",  
        "publisher" : 
        {    
          "@type" : "Organization",    
          "name" : "BitTorrent, Ltd."  
        },  
        "downloadUrl" : "https://www.bittorrent.com/downloads/complete",  
        "operatingSystem" : "WINDOWS",  
        "applicationCategory": "https://schema.org/WebApplication",  
        "offers": 
        {   
           "@type": "Offer",    
           "price": "0",    
           "priceCurrency": "USD"  
        }
      }`}
    </script>
  </>

  const btwebfreemacStructuredData = <>
    <script type='application/ld+json'>
      {`{  
        "@context" : "http://schema.org",  
        "@type" : "SoftwareApplication",  
        "name" : "BitTorrent Web for Mac",  
        "url" : "https://www.bittorrent.com/products/mac/bittorrent-web-free",  
        "publisher" : 
        {    
          "@type" : "Organization",    
          "name" : "BitTorrent, Ltd."  
        },  
        "downloadUrl" : "https://www.bittorrent.com/downloads/complete",  
        "operatingSystem" : "MAC",  
        "applicationCategory": "https://schema.org/WebApplication",
        "offers": 
        {   
           "@type": "Offer",    
           "price": "0",    
           "priceCurrency": "USD"  
        }
      }`}
    </script>
  </>

  const btwebpremStructuredData = <>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"BitTorrent Web Pro",
         "url":"https://www.bittorrent.com/products/win/bittorrent-web-premium",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://store.bittorrent.com/849/purl-btproweb",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/WebApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"19.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"BitTorrent Web Pro+VPN",
         "url":"https://www.bittorrent.com/products/win/bittorrent-web-premium",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://store.bittorrent.com/849/purl-btwebprovpn6995",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/WebApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"69.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
  </>

  const classicfreeStructuredData = <>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"BitTorrent Classic",
         "url":"https://www.bittorrent.com/products/win/bittorrent-classic-free",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://www.bittorrent.com/downloads/complete/classic",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"0",
            "priceCurrency":"USD"
         }
      }`}
    </script>
  </>

  const classicpremStructuredData = <>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"BitTorrent Classic Ad Free",
         "url":"https://www.bittorrent.com/products/win/bittorrent-classic-premium",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://store.bittorrent.com/849/purl-btafwebw",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"4.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"BitTorrent Classic Pro",
         "url":"https://www.bittorrent.com/products/win/bittorrent-classic-premium",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://store.bittorrent.com/849/purl-btproweb",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"19.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
    <script type='application/ld+json'>
      {`{
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"BitTorrent Classic Pro+VPN",
         "url":"https://www.bittorrent.com/products/win/bittorrent-classic-premium",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://store.bittorrent.com/849/purl-btwebprovpn6995",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"69.95",
            "priceCurrency":"USD"
         }
       }`}
    </script>
  </>

  const androidfreeStructuredData = <>
    <script type='application/ld+json'>
      {`{  
         "@context":"http://schema.org",
         "@type":"MobileApplication",
         "name":"BitTorrent Android",
         "url":"https://www.bittorrent.com/products/android/bittorrent-android-free",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://play.google.com/store/apps/details?id=com.bittorrent.client",
         "operatingSystem":"ANDROID",
         "applicationCategory":"https://schema.org/MobileApplication",
         "aggregateRating":{ 
            "@type":"AggregateRating",
            "ratingValue":"4.6",
            "ratingCount":"776188"
         },
         "offers":{ 
            "@type":"Offer",
            "price":"0",
            "priceCurrency":"USD"
         }
      }`}
    </script>
  </>

  const androidproStructuredData = <>
    <script type='application/ld+json'>
      {`{  
         "@context":"http://schema.org",
         "@type":"MobileApplication",
         "name":"BitTorrent Android Pro",
         "url":"https://www.bittorrent.com/products/android/bittorrent-android-pro",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Ltd."
         },
         "downloadUrl":"https://play.google.com/store/apps/details?id=com.bittorrent.client.pro",
         "operatingSystem":"ANDROID",
         "applicationCategory":"https://schema.org/MobileApplication",
         "aggregateRating":{ 
            "@type":"AggregateRating",
            "ratingValue":"4.4",
            "ratingCount":"8069"
         },
         "offers":{ 
            "@type":"Offer",
            "price":"2.99",
            "priceCurrency":"USD"
         }
      }`}
    </script>
  </>

  let isUnlocalizedPage = unlocalizedPageCheck(unlocalizedPath)

  const hrefLangs = <>
    <link rel="alternate" hreflang="de" href={`${siteUrl}/de${unlocalizedPath}`} />
    <link rel="alternate" hreflang="es" href={`${siteUrl}/es${unlocalizedPath}`} />
    <link rel="alternate" hreflang="fr" href={`${siteUrl}/fr${unlocalizedPath}`} />
    <link rel="alternate" hreflang="hi" href={`${siteUrl}/hi${unlocalizedPath}`} />
    <link rel="alternate" hreflang="it" href={`${siteUrl}/it${unlocalizedPath}`} />
    <link rel="alternate" hreflang="ja" href={`${siteUrl}/ja${unlocalizedPath}`} />
    <link rel="alternate" hreflang="ko" href={`${siteUrl}/ko${unlocalizedPath}`} />
    <link rel="alternate" hreflang="nl" href={`${siteUrl}/nl${unlocalizedPath}`} />
    <link rel="alternate" hreflang="pl" href={`${siteUrl}/pl${unlocalizedPath}`} />
    <link rel="alternate" hreflang="pt" href={`${siteUrl}/pt${unlocalizedPath}`} />
    <link rel="alternate" hreflang="pt-br" href={`${siteUrl}/pt-br${unlocalizedPath}`} />
    <link rel="alternate" hreflang="ru" href={`${siteUrl}/ru${unlocalizedPath}`} />
    <link rel="alternate" hreflang="tl" href={`${siteUrl}/tl${unlocalizedPath}`} />
    <link rel="alternate" hreflang="tr" href={`${siteUrl}/tr${unlocalizedPath}`} />
    <link rel="alternate" hreflang="zh-cn" href={`${siteUrl}/zh-cn${unlocalizedPath}`} />
    <link rel="alternate" hreflang="zh-tw" href={`${siteUrl}/zh-tw${unlocalizedPath}`} />
  </> 

  let helmet = <Helmet>
        <html lang={locale}/>
        <title>{meta.title}</title>
        <meta http-equiv="content-type" content="text/html; charset=UTF-8"/>
        <meta name="viewport" content="width=device-width"/>
        <meta name="description" content={description}/>
        <meta name="keywords" content={meta.keywords}/>
        <meta name="author" content="BitTorrent, Ltd."/>
        <meta property="og:url" content={ogUrl}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={meta.title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={socialImg}/>
        <meta property="og:site_name" content="BitTorrent"/>
        <meta name="twitter:site" content="@BitTorrent" />
        <meta name="twitter:image:src" content={socialImg} />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta http-equiv="content-language" content={ locale }/>
        <link href={faviconNew} rel="shortcut icon"/>
        <link rel="alternate" hreflang="en" href={`${siteUrl}${unlocalizedPath}`} />
        <link rel="alternate" hreflang="x-default" href={`${siteUrl}${unlocalizedPath}`} />

        {isHomepage && homepageStructuredData.props.children}
        {isBTWebFree && btwebfreeStructuredData.props.children}
        {isBTWebFreeMac && btwebfreemacStructuredData.props.children}
        {isBTWebPrem && btwebpremStructuredData.props.children}
        {isClassFree && classicfreeStructuredData.props.children}
        {isClassPrem && classicpremStructuredData.props.children}
        {isAndroFree && androidfreeStructuredData.props.children}
        {isAndroPro && androidproStructuredData.props.children}

        {!isUnlocalizedPage && hrefLangs.props.children}
        {/* declaring hrefLangs.props.children is a workaround to use fragment in helmet*/}


        {(meta.isCanonical === undefined || meta.isCanonical) && <link rel="canonical" href={ogUrl} />}
        <link href="/qne4zsu.css" rel="stylesheet"/>
        <link href="/fonts/Inter/inter.css" rel="stylesheet"/>
        {/* <script>
        {`
          window.simplexAsyncFunction = function () {
              Simplex.init({public_key: 'pk_live_00246184-710e-4751-b204-83b701670b62'});
              Simplex.subscribe('onlineFlowFinished', function(event){
                const result = event.payload.result; 
                if(result) {
                  dataLayer.push({ 'event': 'simplex-checkout-' + (result === 'success' ? 'success' : 'failure') });
                  window.location.replace('/token/btt/');                  
                }
              })
          }
        `}
        </script> */}
        {/* <script src="https://cdn.simplex.com/sdk/v1/js/sdk.js" async></script> */}
        {/* Tracking for Simplex Widget */}	
        {/* <script src="https://checkout.simplexcc.com/splx.js" async></script> */}
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10930619333"></script> */}
        {/*
          <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-10930619333', { 'anonymize_ip': true });
          `}
          </script>
        */}

  </Helmet>

  const isAndroid = typeof window !== 'undefined' ? window.gatsbyIsAndroid : false;
  const isIpad = typeof window !== 'undefined' ? window.gatsbyIsIpad : false;
  const isCrOS = typeof window !== 'undefined' ? window.gatsbyIsCrOS : false;
  const isiOS = typeof window !== 'undefined' ? window.gatsbyIsiOS : false;
  const countryCode = getCountry();
  const [banner, setBanner] = useState(null); // lite, android, promotion
  const [ toggleNavPage, setToggleNavPage] = useState(null);

  useEffect(() => {
    if(getShowPromoBanner()) {
      return setBanner("promotion");
    }
    if (!isHomepage) {
      return setBanner("lite");
    } else {
      return setBanner(null);
    }
  }, [countryCode, isIpad, isiOS, isCrOS, isAndroid, isHomepage]);

  const getBanner = (type) => {
    if (type === "lite") {
      return <LiteBanner navText={navText} locale={locale}/>;
    } else if (type === "android") {
      return <AndroidBanner locale = {locale} text = {androidBannerText}/>
    } else if (type === "promotion") {
      return <PromoBanner />;
    }
  }

  let content = <>
    <div className={`container-fluid px-0 mx-0` }>
      <CompatibilityPopup pageContext={{"text":compatibilityText, "locale":locale}}>
        <header className="global-header">
          {
            banner !== null && getBanner(banner)
          }
          <Navigation pageContext={{ "unlocalizedPath": unlocalizedPath, "navText":navText }} toggleNavPage={toggleNavPage} />
        </header>


        <main className={`container-fluid shiroi px-0 ${(banner !== null) ? 'hasBanner':''} ${banner === "android" ? 'isAndroid':''}`}>
          <NavToggleContext.Provider value={(page) => setToggleNavPage(page)}>
            {children}
          </NavToggleContext.Provider>
        </main>

      </CompatibilityPopup>

      <Footer pageContext={{ "footerText":footerText }} />

    </div>
  </>

  if(pageName === "downloads_complete_classic" || pageName === "downloads_complete") {
    content = <>
      <div className="container-fluid px-0 mx-0">
        <CompatibilityPopup pageContext={{"text":compatibilityText, "locale":locale}}>

          {
             banner !== null && getBanner(banner)
          }
          <header className="global-header">
            <Navigation pageContext={{ "unlocalizedPath": unlocalizedPath, "navText":navText}} toggleNavPage={toggleNavPage} />
          </header>

          <main className={`container-fluid shiroi px-0 ${banner !== null ? 'hasBanner':''}`}>
            <NavToggleContext.Provider value={(page) => setToggleNavPage(page)}>
              {children}
            </NavToggleContext.Provider>
          </main>

        </CompatibilityPopup>
      </div>
    </>
  }

  return (
    <LocaleContext.Provider value={{ locale }}>
      {helmet}
      {content}
    </LocaleContext.Provider>
)}


export { Layout, LocaleContext }

const query = graphql`
  query layout {
      data: site {
          siteMetadata {
            siteUrl
          }
        }
    }
  `

function stripHTML(string){
  return string.replace(/<(.|\n)*?>/g, '');
}
