const articleLink = (language, articleId,articleTitle)=> {
    return "/" + language + '/support/solutions/articles/' + articleId + getArticleUrl(articleTitle+"");
}

function getArticleUrl(articleTitle){
    let extraCount = 0;

    articleTitle = articleTitle.replace(/µ/g,function(){
        extraCount++;
        return 'mu'
    })

    articleTitle = articleTitle.replace(/\W+/g,'-');

    articleTitle = articleTitle.replace(/\w/g,function (match){
        return match.toLowerCase();
    });

    articleTitle = articleTitle.substring(0,98+extraCount);

    return "-"+articleTitle;
}



exports.articleLink = articleLink;


const solutionLink = (language, solutionId)=> {
    return "/" + language + '/support/solutions/' + solutionId;
}
exports.solutionLink = solutionLink;


// const helpCenterLink = "help.bittorrent.com";
const helpCenterLink = "/help/";
exports.homeLink = helpCenterLink;

const submitTicket = "/help/helpCenterSubmitTicket/";
exports.submitTicket = submitTicket;





const BT_PLAY = 29000034590;
const BT_WEB = 29000035456;
const BT_CLASSIC = 29000035455;
const BT_SPEED = 29000035341;
const BT_ANDROID = 29000035457;


const ANNOUNCEMENTS = 29000035319;
const BTT_TOKEN = 29000035398;
const GETTING_STARTED = 29000035383;

const UT_CLASSIC = 29000034386;
const UT_WEB = 29000034387;
const UT_ANDROID = 29000034422;
const UT_LIGHT = 29000035437;



exports.BT_PLAY = BT_PLAY;
exports.BT_WEB = BT_WEB;
exports.BT_CLASSIC = BT_CLASSIC;
exports.BT_SPEED = BT_SPEED;
exports.BT_ANDROID= BT_ANDROID;

exports.UT_CLASSIC = UT_CLASSIC;
exports.UT_WEB = UT_WEB;
exports.UT_ANDROID = UT_ANDROID;
exports.UT_LIGHT = UT_LIGHT;


exports.ANNOUNCEMENTS = ANNOUNCEMENTS;
exports.BTT_TOKEN = BTT_TOKEN;
exports.GETTING_STARTED = GETTING_STARTED;




const BT_WEB_MAC = '/products/mac/bittorrent-web-free/';
const BT_WEB_WIN = '/products/win/bittorrent-web-free/';
exports.BT_LINK_WEB_MAC = BT_WEB_MAC;
exports.BT_LINK_WEB_WIN = BT_WEB_WIN;

const UT_WEB_MAC = 'https://www.utorrent.com/web/mac/';
const UT_WEB_WIN = 'https://www.utorrent.com/web/';
exports.UT_LINK_WEB_MAC = UT_WEB_MAC;
exports.UT_LINK_WEB_WIN = UT_WEB_WIN;

const BT_LINK_ANDROID = '/products/android/bittorrent-android-free/';
const UT_LINK_ANDROID = 'https://www.utorrent.com/mobile/';
exports.BT_LINK_ANDROID = BT_LINK_ANDROID;
exports.UT_LINK_ANDROID = UT_LINK_ANDROID;

const BT_CLASSIC_MAC = '/products/win/bittorrent-classic-free/';
const BT_CLASSIC_WIN = '/products/win/bittorrent-classic-free/';
exports.BT_CLASSIC_MAC = BT_CLASSIC_MAC;
exports.BT_CLASSIC_WIN = BT_CLASSIC_WIN;

const UT_CLASSIC_MAC = 'https://www.utorrent.com/downloads/mac/';
const UT_CLASSIC_WIN = 'https://www.utorrent.com/desktop/';
exports.UT_CLASSIC_MAC = UT_CLASSIC_MAC;
exports.UT_CLASSIC_WIN = UT_CLASSIC_WIN;

const UT_LITE = "https://lite.utorrent.com"
exports.UT_LITE = UT_LITE;


const USER_INFO = "userInfo"
exports.USER_INFO = USER_INFO;






